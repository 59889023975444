.App {
  display: flex;
  flex-direction: column;
  height: 100vh;

  align-items: center;
  justify-content: center;
}

.red {
  color: #800000;
}
